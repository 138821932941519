import {Apollo, gql} from 'apollo-angular';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, Renderer2, ViewChild, ViewContainerRef, AfterViewInit, NgZone } from '@angular/core';
import { NgxModalStack } from 'ngx-multi-modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';


import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestsClientService } from 'app/services/requests-client.service';
import { UtilsService } from 'app/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'app/services/connection.service';
import { EntityService } from 'app/services/entity.service';
import { ReferencesModuleService } from 'app/services/clientModules/references.module.service';

@Component({
  selector: 'app-modal-view',
  templateUrl: './modal-view.component.html',
  styleUrls: ['./modal-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConnectionService, RequestsClientService, UtilsService, EntityService, ReferencesModuleService]
})
export class ModalViewComponent implements OnInit {
  @Input() pageKey: String[];
  @Input() title: string = null;
  @Input() data: any = {};
  @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();
  private scriptBuilder = null;

  public pageBuilder = {
    title: 'Personalizado',
    htmlBuild: null
  }

  public dataReady: Boolean = false;

  constructor(
    public toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private requestClient: RequestsClientService,
    private utilsService: UtilsService,
    private zone: NgZone,
    private referencesModuleService: ReferencesModuleService,
  ) {
  }

  async ngOnInit() {
    this.initialize();
    await this.getData(this.pageKey);
    window['braavoRef'] = {
      zone: this.zone,
      componentFn: (action, params) => this.braavoRef(action, params),
      component: this
    };
    await this.startBuilder();
  }

  initialize() {
    this.dataReady = false;
    this.scriptBuilder = null;
    this.pageBuilder = {
      title: this.title,
      htmlBuild: null
    }
  }

  private async getData(ID) {
    const query = gql`
      query getPageManagerByKey($pageKey: String!) {
        getPageManagerByKey(pageKey: $pageKey){
          _id
          name
          key
          script{
            _id
            name
            key
            type
          }
          type
        }
      }
    `;

    const result = await this.apollo.query({
      query: query,
      variables: {
        pageKey: ID
      },
      fetchPolicy: 'network-only'
    }).toPromise();

    if (!!result && !!result.data['getPageManagerByKey'] && !!result.data['getPageManagerByKey'].script) {
      const resultData = result.data['getPageManagerByKey'];
      await this.loadScript(resultData.script)
    }
  }

  private async loadScript(script) {
    if (!!script && script.type === 'PageScript') {
      const fileData = await this.apollo.query({
        query: gql`
            query getScriptFileByID($scriptID: ID!) {
              getScriptFileByID(scriptID: $scriptID)
            }`
        ,
        variables: {
          scriptID: script._id
        },
        fetchPolicy: 'network-only'
      }).toPromise();

      if (!!fileData) {
        this.scriptBuilder = { name: script.key, exec: eval(atob(fileData.data['getScriptFileByID'])) };
      }
    }
  }

  private async startBuilder() {
    try {
      const builder = this.scriptBuilder.exec.builder();
      if (this.requestClient.isValidURL(builder.html)) {
        this.toastrService.error('El recurso HTML debe de ser interno');
        return;
      }
      let html = await this.requestClient.requestFromInternatGetFile(this.scriptBuilder.name, builder.html);
      html = atob(html.file);
      this.injectHTML(html);
      this.dataReady = true;
      await this.sleep(50);
      if (!!builder.scripts && builder.scripts.length > 0) {
        const contextScript = `
          var BraavoContext = (action, params) => {
            return window.braavoRef.zone.run(
              function () {
                return window.braavoRef.componentFn(action, params);
              }
            );
          }
        `;
        const blob = this.utilsService.base64ToArrayBuffer(btoa(contextScript), 'text/javascript', 512);
        const url = URL.createObjectURL(blob);
        this.injectScript(this.scriptBuilder.name, url);

        for (const script of builder.scripts) {
          if (this.requestClient.isValidURL(script)) {
            this.injectScript(this.scriptBuilder.name, script);
          } else {
            const scriptData = await this.requestClient.requestFromInternatGetFile(this.scriptBuilder.name, script);
            const blob = this.utilsService.base64ToArrayBuffer(scriptData.file, 'text/javascript', 512);
            const url = URL.createObjectURL(blob);
            this.injectScript(this.scriptBuilder.name, url);
          }
        }
      }
      if (!!builder.css && builder.css.length > 0) {
        for (const css of builder.css) {
          if (this.requestClient.isValidURL(css)) {
            this.injectCSS(this.scriptBuilder.name, css);
          } else {
            const cssData = await this.requestClient.requestFromInternatGetFile(this.scriptBuilder.name, css);
            const blob = this.utilsService.base64ToArrayBuffer(cssData.file, 'text/css', 512);
            const url = URL.createObjectURL(blob);
            this.injectCSS(this.scriptBuilder.name, url);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  private injectHTML(html) {
    this.pageBuilder.htmlBuild = this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private injectScript(ID, url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.id = ID;
    document.getElementsByTagName('app-modal-view')[0].appendChild(node);
  }

  private injectCSS(ID, url) {
    let node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    node.id = ID;
    document.getElementsByTagName('app-modal-view')[0].appendChild(node);
  }

  ngOnDestroy(): void {
    this.cleanReferences();
  }

  private cleanReferences() {
    if (!!this.scriptBuilder) {
      while (document.getElementById(this.scriptBuilder.name)) {
        let fDocument = document.getElementById(this.scriptBuilder.name);
        let url = fDocument.getAttribute('src');
        if (!url) url = fDocument.getAttribute('href');
        if (!!url) URL.revokeObjectURL(url);
        document.getElementsByTagName('app-modal-view')[0].removeChild(fDocument);
        fDocument.remove();
      }
      window['braavoRef'] = null;
    }
  }

  private braavoRef(action: string, params) {
    switch (action) {
      case 'getModelData': return this.data;
      case 'closeModal': return this.onFinish.emit(params);
      default: return this.referencesModuleService.runCommand(action, params, 'modal');
    }
  }

  private sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  closeModal() {
    this.onFinish.emit(null);
  }

}
