import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMultiModalModule } from 'ngx-multi-modal';
import { DownloadService } from './services/download.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalsService } from './services/modals.service';
import { MomentModule } from 'ngx-moment';
import { ComponentsModule } from './pages/components/components.module';
import { UserService } from './services/user.service';
import { ConnectionService } from './services/connection.service';

@NgModule({
  imports: [
    CommonModule,
    NgxMultiModalModule.forRoot({ backdrop: true, container: 'body', size: 'lg', dismissOnBackdropClick: false, keyboard: false }),
    MomentModule.forRoot(),
    ComponentsModule,
  ],
  exports: [
    NgxMultiModalModule,
    MomentModule,
    ComponentsModule
  ],
  providers: [
    ConnectionService,
    CookieService,
    DownloadService,
    ModalsService,
    UserService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }

  constructor(private braavoModalService: ModalsService) { }
}
