import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { JwtHelperService  } from '@auth0/angular-jwt';

import { UserService } from './user.service';

@Injectable()
export class AccountService {
  private jwtHelperService : JwtHelperService  = new JwtHelperService ();

  constructor(private apollo: Apollo, private router: Router, private permissionsService: NgxPermissionsService, private userService: UserService) { }

  public async loginFromWeb(sessionData) {
    sessionStorage.clear();
    this.permissionsService.flushPermissions();

    const serverResponse = await this.apollo.query({
      query: gql`
                    query loginFromWeb($username: String!, $password: String!) {
                        loginFromWeb(username: $username, password: $password) 
                    }
                `,
      variables: {
        username: sessionData.username,
        password: sessionData.password,
      },
      fetchPolicy: 'network-only'
    }).toPromise();
    if (!!serverResponse.data['loginFromWeb']) {
      sessionStorage.setItem('token', serverResponse.data['loginFromWeb']);
      await this.loadPermission();
      // console.log(this.permissionsService.getPermissions())
      this.router.navigate(['pages/dashboard']);
    }
  }

  public async loginWithToken(token) {
    const serverResponse = await this.apollo.query({
      query: gql`
          query loginWithToken($token: String!) {
              loginWithToken(token: $token) 
          }
      `,
      variables: {
        token: token,
      },
      fetchPolicy: 'network-only'
    }).toPromise();
    if (!serverResponse.data['loginWithToken']) {
      sessionStorage.clear();
      this.permissionsService.flushPermissions();
      this.router.navigate(['/login'], { replaceUrl: true });
    } else {
      await this.loadPermission();
      this.router.navigate(['pages/dashboard'], { replaceUrl: true });
    }
  }

  private async loadPermission() {
    if (sessionStorage.getItem('token')) {
      const tokenData = this.jwtHelperService.decodeToken(sessionStorage.getItem('token'));
      if (!!tokenData && !!tokenData.rol) {
        const serverResponse = await this.apollo.query({
          query: gql`
          query getEmployeePermissions($roles: JSON) {
              getEmployeePermissions(roles: $roles) 
          }
      `,
          variables: {
            roles: { $in: [tokenData.rol] }
          },
          fetchPolicy: 'network-only'
        }).toPromise();
        if (!!serverResponse.data['getEmployeePermissions']) {
          this.permissionsService.flushPermissions();
          this.permissionsService.loadPermissions(['root', ...serverResponse.data['getEmployeePermissions'].map(m => m.clave)]);
          await this.userService.initUser(tokenData.id, sessionStorage.getItem('token'), tokenData.branch);
        }
      } else {
        this.permissionsService.flushPermissions();
        this.permissionsService.loadPermissions(['root']);
        await this.userService.initUser(tokenData.id, sessionStorage.getItem('token'), tokenData.branch);
      }
    } else {
      this.logout();
    }
  }

  public async logout() {
    sessionStorage.clear();
    this.permissionsService.flushPermissions();
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
