import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ApolloService {

  private graphQLAPI = {
    protocol: environment.connection.protocol,
    host: environment.connection.host,
    port: environment.connection.port,
    dir: environment.connection.apiGraphQL
  };

  private currentUri =
    this.graphQLAPI.protocol +
    '://' +
    this.graphQLAPI.host +
    ':' +
    this.graphQLAPI.port +
    this.graphQLAPI.dir;

  constructor() { }

  getUri() {
    return this.currentUri;
  }
}
