import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService  } from '@auth0/angular-jwt';
import { permissionRead } from '@syncfusion/ej2-angular-filemanager';
import { ActionModalsComponent } from 'app/pages/components/action-modals/action-modals.component';
import { NgxModalStack, NgxModalOptions, ComponentModalRef } from 'ngx-multi-modal';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DownloadService } from '../download.service';
import { EntityService } from '../entity.service';
import { ModulesClientService } from '../modules-client.service';
import { RequestsClientService } from '../requests-client.service';
import { UserService } from '../user.service';
import { UserModuleService } from './user.module.service';

@Injectable({
    providedIn: 'root'
})
export class ReferencesModuleService {

    private jwtHelperService : JwtHelperService  = new JwtHelperService ();
    private loader = null;

    constructor(
        private router: Router,
        private downloadService: DownloadService,
        private requestClient: RequestsClientService,
        private modulesClientService: ModulesClientService,
        private entityService: EntityService,
        private userModuleService: UserModuleService,
        private toastrService: ToastrService,
        private modalService: NgxModalStack,
        private ngZone: NgZone
    ) { }


    public runCommand(action, params, type) {
        switch (action) {
            case 'goToViewEntity':
                if (type === 'modal') {
                    throw new Error("Accion no permitida para la vista en modal");
                }
                if (!params.entity) {
                    throw new Error("Se requiere el parametro 'entity' con el nombre de la entidad a redireccionar");
                }
                if (!!params.id) {
                    this.router.navigate(['/pages/entity/view'], { queryParams: { entity: params.entity, id: params.id, t: new Date().getTime() } });
                } else {
                    this.router.navigate(['/pages/entity'], { queryParams: { id: params.entity, t: new Date().getTime() } });
                }
                break;
            case 'downloadFile': {
                this.downloadService.extractData(params.data, params.mime, params.filename);
                break;
            }
            case 'requestFromInternalEndPoint': {
                return this.requestClient.requestFromInternalEndPoint(params.endPoint, params.body);
            }

            // UserAction
            case 'getCurrentUser': {
                return this.userModuleService.getCurrentUser();
            }
            case 'getCurrentBranch': {
                return this.userModuleService.getCurrentBranch();
            }
            case 'getPermission': {
                return this.userModuleService.getPermission(params);
            }
            case 'getAllPermissions': {
                return this.userModuleService.getAllPermissions();
            }

            //EntityAction
            case 'searchEntityData': {
                if (!params.entityName) {
                    throw new Error("Nombre de la entidad no proporcionada");
                }
                if (!params.filter) {
                    params.filter = {}
                }
                if (!params.field) {
                    params.field = [];
                }
                if (!params.limit) {
                    params.limit = 1000;
                }
                if (!params.skip) {
                    params.skip = 0;
                }
                if (!params.sort) {
                    params.sort = { createdDate: -1 };
                }
                return this.entityService.searchEntity(params.entityName, params.filter, params.field, params.limit, params.skip, params.sort);
            }
            case 'countEntityData': {
                if (!params.entityName) {
                    throw new Error("Nombre de la entidad no proporcionada");
                }
                if (!params.filter) {
                    params.filter = {}
                }
                return this.entityService.countEntity(params.entityName, params.filter);
            }

            // CustomListAction
            case 'getListSource': {
                if (!params.listID) {
                    throw new Error("Nombre de la lista no proporcionada");
                }
                return this.entityService.getListSource(params.listID);
            }

            // MessageAtions
            case 'showMessage': {
                if (!params.message) {
                    throw new Error("Se requiere el paramentro 'message' para esta funcion");
                }
                switch (params.type) {
                    case 'error':
                        this.toastrService.error(params.message, params.title || '');
                        break;
                    case 'success':
                        this.toastrService.success(params.message, params.title || '');
                        break;
                    case 'warning':
                        this.toastrService.warning(params.message, params.title || '');
                        break;
                    case 'info':
                        this.toastrService.info(params.message, params.title || '');
                        break;
                    default:
                        this.toastrService.show(params.message, params.title || '');
                        break;
                }
                break;
            }
            case 'loader': {
                switch (params.action) {
                    case 'start':
                        if (!params.message) {
                            throw new Error("Se requiere el paramentro 'message' para esta accion");
                        }
                        this.loader = this.modulesClientService.loader(params.message);
                        break;
                    case 'close':
                        if(!this.loader){
                            throw new Error("El loader no ha sido iniciado, se requiere usar la accion 'start' para iniciarla");
                        }
                        this.loader.close();
                        this.loader = null;
                        break;
                    default:
                        throw new Error("Se requiere la accion 'start' o 'close'");
                }
                break;
            }
        }
    }
}
