import { Component, ViewEncapsulation, OnInit, HostListener } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AccountService } from './services/account.service';
import { NgxModalStack } from 'ngx-multi-modal';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    public settings: Settings;

    @HostListener('window:popstate', ['$event'])
    @HostListener('window:keydown.escape')
    closeModals(event?: any) {
        const pressESC = new KeyboardEvent('keydown', {
            'key': 'Escape'
        });
        document.dispatchEvent(pressESC);
    }

    constructor(
        public modalService: NgxModalStack,
        public appSettings: AppSettings,
        private accountService: AccountService
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        if (sessionStorage.getItem('token')) {
            this.accountService.loginWithToken(sessionStorage.getItem('token'));
        };
    }


}
