import { Apollo, gql } from "apollo-angular";
import { Injectable, NgZone } from "@angular/core";
import { FormGroup, AbstractControl, Validators } from "@angular/forms";

import { first } from "rxjs/operators";
import { NgxModalStack } from "ngx-multi-modal";
import { LoadingModalComponent } from "app/pages/components/loading-modal/loading-modal.component";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ModulesClientService {
  private jwtHelperService: JwtHelperService = new JwtHelperService();
  // INICIALIZACION
  constructor(
    private router: Router,
    private modalService: NgxModalStack,
    private apollo: Apollo,
    private userService: UserService,
    private ngZone: NgZone
  ) {}

  public initRecord(operation, entityID, entityKey, builder, formGroup?) {
    Object.defineProperty(this, "recordOperation", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: operation,
    });
    Object.defineProperty(this, "recordID", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: entityID,
    });
    Object.defineProperty(this, "recordName", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: entityKey,
    });
    if (this.hasOwnProperty("builder")) {
      this["builder"] = builder;
    } else {
      Object.defineProperty(this, "builder", {
        enumerable: false,
        writable: true,
        configurable: true,
        value: builder,
      });
    }
    Object.defineProperty(this, "form", {
      enumerable: false,
      writable: true,
      configurable: true,
      value: formGroup,
    });
  }

  // OPERACIONES PUBLICAS
  public getValue(key) {
    switch (this["recordOperation"]) {
      case "view":
        let value = null;
        return !!this["builder"]["data"][key]
          ? this["builder"]["data"][key].value
          : null;
      case "create":
      case "edit":
        const control = this.searchFieldInFG(key);
        return !!control ? control.value : null;
    }
    return null;
  }

  public getText(key) {
    let res = null;
    const control = this.searchFieldInFG(key);
    const field = this.searchForm(key);
    if (!!control && field) {
      if (field.options.length > 0) {
        const finded = field.options.find((f) => f.key === control.value);
        res = !!finded ? finded.value : null;
      }
    }

    return res;
  }

  public async setValue(key, value) {
    console.log(key, value);
    if (this["recordOperation"] === "view") {
      return;
    }
    const control = this.searchFieldInFG(key);
    const field = this.searchField(key);
    const form = this.searchForm(key);
    if (!field && !form && !control) {
      throw new Error(`No existe el campo ${key} en la entidad`);
    }
    switch (form.type) {
      case "Formula":
      case "Number":
        if (isNaN(value)) {
          throw new Error("Se requiere una numero para este campo");
        }
        break;
      case "Date":
        if (!(value instanceof Date)) {
          throw new Error(`Se requiere una fecha para el campo ${key}`);
        }
        break;
    }
    switch (form.type) {
      case "Radio":
      case "Select": {
        if (
          (form.manyOptions || form.options === null) &&
          field.field.typeSource !== "List"
        ) {
          await this.loadIndividualRecordFromMany(form, field, control, value);
        } else {
          control.patchValue(value);
        }
        break;
      }
      default:
        control.patchValue(value);
        break;
    }
  }

  public openModal(componentView, { ...params }, callback) {
    const modal: any = this.modalService.openFromComponent(componentView, {});
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        modal.instance[key] = params[key];
      }
    }
    modal.instance.onFinish.subscribe(async (result) => {
      modal.close();
      if (!!result) {
        const loadingModal: any = this.modalService.openFromComponent(
          LoadingModalComponent
        );
        loadingModal.instance.actionText = "Cargando...";
        await callback(result);
        loadingModal.close();
      }
    });
  }

  public loader(text) {
    const modal: any = this.modalService.openFromComponent(
      LoadingModalComponent
    );
    modal.instance["actionText"] = text;
    return modal;
  }

  public displayButton(button, display) {
    if (
      this["builder"]["buttons"].findIndex((f) => f.key === button) === -1 &&
      this["builder"]["buttonsSecondary"].findIndex((f) => f.key === button) ===
        -1 &&
      button !== "edit"
    ) {
      throw new Error(`No existe el boton ${button}`);
    }

    this.ngZone.run(() => {
      this["builder"]["hiddenButton"][button] = !display;
    });

    // Habilitar boton secundario
    const totalSubActions = this["builder"]["buttonsSecondary"].length;
    let currentHidden = 0;
    for (const key in this["builder"]["hiddenButton"]) {
      if (
        Object.prototype.hasOwnProperty.call(
          this["builder"]["hiddenButton"],
          key
        )
      ) {
        const finded =
          this["builder"]["buttonsSecondary"].findIndex(
            (f) => f.key === key
          ) !== -1;
        if (finded && !!this["builder"]["hiddenButton"][key]) {
          currentHidden++;
        }
      }
    }

    if (totalSubActions === currentHidden) {
      this.ngZone.run(() => {
        this["builder"]["activeButtonsSecondary"] = false;
      });
    } else {
      this.ngZone.run(() => {
        this["builder"]["activeButtonsSecondary"] = true;
      });
    }
  }

  public displayField(field, display) {
    switch (this["recordOperation"]) {
      case "view": {
        const fieldFinded = this["builder"]["properties"].findIndex(
          (f) => f.key === field
        );
        if (fieldFinded === -1) {
          throw new Error(`No existe el campo ${field}`);
        }

        this["builder"]["properties"][fieldFinded].visible = display;
        break;
      }
      case "create":
      case "edit": {
        const fieldFinded = this["builder"]["form"].findIndex(
          (f) => f.key === field
        );
        if (fieldFinded === -1) {
          throw new Error(`No existe el campo ${field}`);
        }
        this["builder"]["form"][fieldFinded].visible = display;
      }
    }
  }

  public disabledField(field, disabled) {
    if (this["recordOperation"] === "view") {
      return;
    }
    const fieldFinded = this["builder"]["form"].findIndex(
      (f) => f.key === field
    );
    const control = this.searchFieldInFG(field);
    if (fieldFinded === -1 && control) {
      throw new Error(`No existe el campo ${field}`);
    }
    this["builder"]["form"][fieldFinded].disabled = disabled;

    if (disabled || this["builder"]["form"][fieldFinded].manyOptions) {
      (<AbstractControl>control).disable();
    } else {
      (<AbstractControl>control).enable();
    }
  }

  public requiredField(field, required) {
    if (this["recordOperation"] === "view") {
      return;
    }
    const fieldFinded = this["builder"]["form"].findIndex(
      (f) => f.key === field
    );
    const control = this.searchFieldInFG(field);
    if (fieldFinded === -1 && control) {
      throw new Error(`No existe el campo ${field}`);
    }
    this["builder"]["form"][fieldFinded].required = required;

    if (required) {
      control.setValidators(Validators.required);
      control.updateValueAndValidity();
    } else {
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  public getCurrentUser() {
    return this.jwtHelperService.decodeToken(sessionStorage.getItem("token"));
  }

  public getCurrentBranch() {
    return this.userService.getCurrentBranch();
  }

  public goToViewEntity(
    entityName,
    entityID,
    options: {
      operation: "V" | "E" | "C";
      defaultValues: any;
      saveHistory: boolean;
    } = { operation: "V", defaultValues: {}, saveHistory: true }
  ) {
    switch (options.operation) {
      case "V":
        if (!entityID) {
          throw new Error("El ID de la entidad debe tener un valor valido");
        }
        this.router.navigate(["/pages/entity/view"], {
          queryParams: {
            entity: entityName,
            id: entityID,
            t: new Date().getTime(),
          },
          replaceUrl: options.saveHistory,
        });
        break;
      case "E":
        if (!entityID) {
          throw new Error("El ID de la entidad debe tener un valor valido");
        }
        this.router.navigate(["/pages/entity/edit"], {
          queryParams: {
            entity: entityName,
            id: entityID,
            op: "E",
            t: new Date().getTime(),
          },
          replaceUrl: options.saveHistory,
        });
        break;
      case "C":
        if (!!entityID) {
          throw new Error(
            "El ID de la entidad debe ser nulo si se crea un registro"
          );
        }
        this.router.navigate(["/pages/entity/edit", options.defaultValues], {
          queryParams: {
            entity: entityName,
            id: entityID,
            op: "C",
            t: new Date().getTime(),
          },
          replaceUrl: options.saveHistory,
        });
        break;
    }
  }

  public displayListValue(fieldToUpdateList, values = [], display = true) {
    if (this["recordOperation"] === "view") {
      return;
    }
    if (values instanceof Array) {
      const field = this.searchForm(fieldToUpdateList);
      if (!field) {
        throw new Error(
          `El campo ${fieldToUpdateList} no se encontro en el registro actual`
        );
      }
      if (!field.options) {
        throw new Error(
          `El campo ${fieldToUpdateList} no contiene elementos a iterar`
        );
      }
      for (const option of field.options) {
        if (!!option.key) {
          if (values.includes(option.key)) {
            option.show = display;
          } else {
            option.show = !display;
          }
        }
      }
    } else {
      throw new Error(
        `Los valores del campo ${fieldToUpdateList} debe de ser un Arreglo`
      );
    }
  }

  // public updateSourceList(field){

  //   await this.loadIndividualRecordFromMany(form, field, control, value);
  // }

  // OPERACIONES PRIVADAS
  private searchFieldInFG(controlName) {
    let res = null;
    const fieldFound = this["builder"]["fields"].find(
      (f) => f.field.key === controlName
    );
    let tabFound = null;
    if (!!fieldFound && !!fieldFound.tab) {
      tabFound = this["builder"]["tabs"].find(
        (f) => f.tabId === fieldFound.tab
      ).tabKey;
    }
    if (!!tabFound) {
      res = this["form"].get(tabFound).get(controlName);
    }
    return res;
  }

  private searchField(keyField) {
    const fieldFound = this["builder"]["fields"].find(
      (f) => f.field.key === keyField
    );
    return fieldFound;
  }

  private searchForm(keyField) {
    const fieldFound = this["builder"]["form"].find((f) => f.key === keyField);
    return fieldFound;
  }

  private async loadIndividualRecordFromMany(
    field,
    fieldBuild,
    control,
    value
  ) {
    console.log(fieldBuild.field.key, "despues");
    if (!!value) {
      const query = gql`
        query getEntityDataListByID(
          $entityID: ID!
          $noIncludeData: JSON
          $filters: JSON
        ) {
          getEntityDataListByID(
            entityID: $entityID
            noIncludeData: $noIncludeData
            filters: $filters
          )
        }
      `;

      console.log({
        query: query,
        variables: {
          entityID: fieldBuild.field.source,
          noIncludeData: [],
          filters: { _id: value },
        },
        fetchPolicy: "network-only",
      });
      const listData = await this.apollo
        .query({
          query: query,
          variables: {
            entityID: fieldBuild.field.source,
            noIncludeData: [],
            filters: { _id: value },
          },
          fetchPolicy: "network-only",
        })
        .toPromise();
      const newOption = [];
      newOption.push(
        ...listData.data["getEntityDataListByID"].data.map((m) => {
          return { key: m.value, value: m.name };
        })
      );
      this.ngZone.run(() => {
        field.options = newOption;
        control.patchValue(value);
      });
    } else {
      this.ngZone.run(() => {
        field.options = [{ key: null, value: " - Buscar registros - " }];
        control.patchValue(value);
      });
    }
  }
}
