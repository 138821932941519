import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsSelectPointComponent } from './google-maps-select-point/google-maps-select-point.component';
import { GoogleMapsViewPointComponent } from './google-maps-view-point/google-maps-view-point.component';
import { ActionModalsComponent } from './action-modals/action-modals.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { FileManagerBraavoComponent } from './file-manager-braavo/file-manager-braavo.component';
import { FileManagerModule } from '@syncfusion/ej2-angular-filemanager';
import { ModalViewComponent } from './modal-view/modal-view.component';
import { environment } from 'environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ChangeBranchComponent } from './change-branch/change-branch.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AgmCoreModule,
        FileManagerModule,
        HttpClientModule,
        AgmCoreModule.forRoot(environment.googleMaps),
    ],
    declarations: [
        GoogleMapsSelectPointComponent,
        GoogleMapsViewPointComponent,
        ActionModalsComponent,
        LoadingModalComponent,
        FileManagerBraavoComponent,
        ModalViewComponent,
        ChangeBranchComponent
    ],
    exports: [
        GoogleMapsViewPointComponent
    ]
})
export class ComponentsModule {

  constructor() { }
}
