// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  connection: {
    protocol: 'https',
    host: 'dev.creditoplanreal.com.mx',
    port: '443',
    apiGraphQL: '/api'
  },
  defaultRest: {
    deploymentsREST: '/system/rest/deploy',
    getFileREST: '/system/rest/getFile',
  },
  firebase: {
    apiKey: 'AIzaSyB-bn2Dcjf_dtrOLmns1aKIwfOGwD3BzKg',
    authDomain: 'braavo-b7305.firebaseapp.com',
    databaseURL: 'https://braavo-b7305.firebaseio.com',
    projectId: 'braavo-b7305',
    storageBucket: 'braavo-b7305.appspot.com',
    messagingSenderId: '30179666805'
  },
  googleMaps: {
    apiKey: 'AIzaSyAclDjM-DU7QzDalOFIUOuLHVg2xfgRqk4'
  }
};
