import {Apollo} from 'apollo-angular';
import {onError} from '@apollo/client/link/error';
import {createHttpLink} from '@apollo/client/link/http';
import {ApolloLink, ApolloClient, InMemoryCache} from '@apollo/client/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routing } from './app.routing';
import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';


import { NgxPermissionsModule } from 'ngx-permissions';
import { AccountService } from './services/account.service';
import { ToastrService, ToastrModule } from 'ngx-toastr';

import { ApolloService } from './apollo/apollo.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { ModalsService } from './services/modals.service';
import { SharedModule } from './shared.module';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SharedModule.forRoot(),
    Routing,
  ],
  providers: [
    AppSettings,
    UserService,
    AccountService,
    ApolloService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(apollo: Apollo, toastrService: ToastrService, apolloService: ApolloService, private braavoModalService: ModalsService) {
    setTheme('bs4');


    const linkInterface = createHttpLink({ uri: apolloService.getUri() });

    const auth = new ApolloLink((operation, forward) => {
      let token = null;
      if (sessionStorage.getItem('token')) {
        token = sessionStorage.getItem('token');
      }
      if (!token) {
        return forward(operation);
      } else {
        operation.setContext({
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token') || null,
            'Access-Control-Allow-Origin': '*'
          }
        });
        return forward(operation);
      }
    });

    const error = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) =>
          toastrService.error(message, 'Error Servidor!')
        );
      }
      if (networkError) {
        if (networkError.message === 'Failed to fetch') {
          toastrService.error('Sin conexion con el Servidor', 'Error Conexion!')
        } else {
          //-console.log(`[Network error]: ${networkError.message}`);
        }
      }
    });

    const link = error.concat(auth.concat(linkInterface));

    const clienteApollo = new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
    });

    apollo.client = clienteApollo;
  }
}
