import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AgmMap } from '@agm/core';
import { AccountService } from '../../../services/account.service';

@Component({
  selector: 'app-google-maps-select-point',
  templateUrl: './google-maps-select-point.component.html',
  styleUrls: ['./google-maps-select-point.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleMapsSelectPointComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AgmMap, { static: true }) private agmMap: AgmMap;
  @Input() position: any;
  @Output() onFinish: EventEmitter<Object> = new EventEmitter<Object>();
  public zoom: number = 15;
  map: any;

  constructor(public accountService: AccountService) {

  }

  ngOnDestroy() { }

  ngOnInit() {
    this.agmMap.disableDefaultUI = true;
    // this.agmMap.
    this.agmMap.clickableIcons = false;
  }

  ngAfterViewInit() {
    this.agmMap.triggerResize();
  }

  mapReady(map) {
    this.map = map;
    this.agmMap.triggerResize();
    if (!this.position.lat || !this.position.lng) {
      this.agmMap.latitude = 18.644415;
      this.agmMap.longitude = -91.806706;
    }
  }

  rezise() {
    this.agmMap.triggerResize();
  }

  confirm() {
    this.position.lat = this.agmMap.latitude;
    this.position.lng = this.agmMap.longitude;
    this.onFinish.emit(this.position);
  }

  closeModal() {
    this.onFinish.emit(null);
  }
}
