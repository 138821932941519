import { Injectable, Type, Component, Renderer2, RendererFactory2 } from '@angular/core';
import { ComponentModalRef, NgxModalStack } from 'ngx-multi-modal';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  private renderer2: Renderer2;
  constructor(
    private modalService: NgxModalStack,
    _renderer: RendererFactory2,
  ) {
    this.renderer2 = _renderer.createRenderer(null, null);
    this.catchModals();
  }

  public catchModals() {
    this.modalService.onUnregister.subscribe(data => {
      if (data > 0) {
        this.renderer2.addClass(document.body, 'modal-open');
      } else {
        setTimeout(() => {
          if (this.modalService.count === 0) this.renderer2.removeClass(document.body, 'modal-open');
        }, 300);
      }
    });
  }
}
