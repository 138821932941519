import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ConnectionService {

  private defaultAPI = {
    protocol: environment.connection.protocol,
    host: environment.connection.host,
    port: environment.connection.port
  };

  private defaultPath = {
    deploymentsREST: environment.defaultRest.deploymentsREST,
    getFileREST: environment.defaultRest.getFileREST,
  };

  constructor() { }


  getUri() {
    if (environment.production) {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}`;
    } else {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}`;
    }
  }


  getUriDeployREST() {
    if (environment.production) {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}${this.defaultPath.deploymentsREST}`;
    } else {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}${this.defaultPath.deploymentsREST}`;
    }
  }

  getUriFileREST() {
    if (environment.production) {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}${this.defaultPath.getFileREST}`;
    } else {
      return `${this.defaultAPI.protocol}://${this.defaultAPI.host}:${this.defaultAPI.port}${this.defaultPath.getFileREST}`;
    }
  }

}