import { Injectable } from '@angular/core';
import { JwtHelperService  } from '@auth0/angular-jwt';
import { permissionRead } from '@syncfusion/ej2-angular-filemanager';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../user.service';

@Injectable({
    providedIn: 'root'
})
export class UserModuleService {

    private jwtHelperService : JwtHelperService  = new JwtHelperService ();

    constructor(
        private userService: UserService,
        private permissionsService: NgxPermissionsService,
    ) { }

    public getCurrentUser() {
        return this.jwtHelperService .decodeToken(sessionStorage.getItem('token'));
    }

    public getCurrentBranch() {
        return this.userService.getCurrentBranch();
    }

    public getPermission(permissionName: string) {
        return !!this.permissionsService.getPermission(permissionName);
    }

    public getAllPermissions() {
        return this.permissionsService.getPermissions();
    }


}
