import { Component, OnInit, ViewEncapsulation, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-modals',
  templateUrl: './action-modals.component.html',
  styleUrls: ['./action-modals.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionModalsComponent implements OnInit {
  @Input() actionText: String[];
  @Output() onConfirm: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit() {
    if (this.actionText === null) {
      this.cancel();
    }
  }

  confirm() {
    this.onConfirm.next(true);
  }

  cancel() {
    this.onConfirm.next(false);
  }

}
