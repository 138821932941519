import {Apollo, gql} from 'apollo-angular';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { JwtHelperService  } from '@auth0/angular-jwt';

import { ConnectionService } from './connection.service';

@Injectable()
export class UserService {
  private userID = null;
  private userToken = null;
  private branchs = [];
  private currentBranch = null;

  private changesBranch = new EventEmitter<Boolean>();

  constructor(private router: Router, private apollo: Apollo, private connectionService: ConnectionService) {

  }

  public async initUser(user, token, branchs) {
    this.userID = user;
    this.userToken = token;
    this.branchs = branchs;
    await this.initCurrentBranch();
  }

  private async initCurrentBranch() {
    const serverResponse = await this.apollo.query({
      query: gql`
          query getCurrentBranch($employeeID: ID!) {
              getCurrentBranch(employeeID: $employeeID) 
          }
      `,
      variables: {
        employeeID: this.userID,
      },
      fetchPolicy: 'network-only'
    }).toPromise();
    this.currentBranch = {
      id: serverResponse.data['getCurrentBranch'].currentBranch._id,
      name: serverResponse.data['getCurrentBranch'].currentBranch.name,
      direccion: serverResponse.data['getCurrentBranch'].currentBranch.direccion,
      code: serverResponse.data['getCurrentBranch'].currentBranch.code,
      subsidiaryID: serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria._id,
      subsidiaryName: serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.razon_social,
      image: !!serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo ? this.getImageUURL(serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria._id, serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo.folder, serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo.name) : null,
      imageBgBlack: !!serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo_fondo_negro && !!serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo_fondo_negro.name ? this.getImageUURL(serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria._id, serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo_fondo_negro.folder, serverResponse.data['getCurrentBranch'].currentBranch.subsidiaria.logo_fondo_negro.name, 'alt') : null,

    };
    this.changesBranch.emit(true);
  }


  listeningBranchChanged() {
    return this.changesBranch;
  }

  public getCurrentBranch() {
    return this.currentBranch;
  }

  public async setCurrentBranch(branchData) {
    await this.apollo.mutate({
      mutation: gql`
          mutation setCurrentBranch($employeeID: ID!, $branchID: ID!) {
              setCurrentBranch(employeeID: $employeeID, branchID: $branchID) 
          }
      `,
      variables: {
        employeeID: this.userID,
        branchID: branchData.id,
      }
    }).toPromise();

    this.currentBranch = branchData;
    this.changesBranch.emit(true);
    this.router.navigate(['/pages/dashboard'], { queryParams: { t: new Date().getTime() } });
  }

  getImageUURL(subsidiariaID, folder, logoName, type = 'nor') {
    return `${this.connectionService.getUri()}/fileManager/GetImage?path=${folder}/${subsidiariaID}/${logoName}`
  }

}
