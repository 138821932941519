import {Apollo, gql} from 'apollo-angular';
import { Component, OnInit, ViewEncapsulation, Output, Input, EventEmitter } from '@angular/core';

import { ConnectionService } from 'app/services/connection.service';
import { UserService } from 'app/services/user.service';


@Component({
  selector: 'app-change-branch',
  templateUrl: './change-branch.component.html',
  styleUrls: ['./change-branch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConnectionService]
})
export class ChangeBranchComponent implements OnInit {
  @Input() userID: String;
  @Output() onConfirm: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  public branchsData = [];

  constructor(private apollo: Apollo, private connectionService: ConnectionService, private userService: UserService) { }

  ngOnInit() {
    this.getBranchsUser();
  }

  async getBranchsUser() {
    const serverResponse = await this.apollo.query({
      query: gql`
                    query getEmployeeBranchs($employeeID: ID!) {
                        getEmployeeBranchs(employeeID: $employeeID) 
                    }
                `,
      variables: {
        employeeID: this.userID,
      },
      fetchPolicy: 'network-only'
    }).toPromise();
    this.branchsData = serverResponse.data['getEmployeeBranchs'].map(m => {
      return {
        id: m._id,
        name: m.name,
        direccion: m.direccion,
        code: m.code,
        subsidiaryID: m.subsidiaria._id,
        subsidiaryName: m.subsidiaria.razon_social,
        image: !!m.subsidiaria.logo ? this.getImageUURL(m.subsidiaria._id, m.subsidiaria.logo.folder, m.subsidiaria.logo.name) : null,
        imageBgBlack: !!m.subsidiaria.logo_fondo_negro && !!m.subsidiaria.logo_fondo_negro.name ? this.getImageUURL(m.subsidiaria._id, m.subsidiaria.logo_fondo_negro.folder, m.subsidiaria.logo_fondo_negro.name, 'alt') : null,
      }
    });
  }

  getImageUURL(subsidiariaID, folder,logoName, type = 'nor') {
    return `${this.connectionService.getUri()}/fileManager/GetImage?path=${folder}/${subsidiariaID}/${logoName}`
  }

  selectBranch(branch) {
    this.userService.setCurrentBranch(branch);
    this.onConfirm.next(true);
  }

  closeModal() {
    this.onConfirm.next(null);
  }

}
