import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';


import { first } from 'rxjs/operators';
import { NgxModalStack } from 'ngx-multi-modal';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  // INICIALIZACION
  constructor(
    private apollo: Apollo,
    private toastService: ToastrService
  ) { }

  async searchEntity(entityName, filter, field, limit = 1000, skip = 0, sort = {
    createdDate: -1
  }) {
    const query = gql`
        query clientActionSearchEntity($entityName: String!, $filter: JSON!, $field: JSON!, $limit: Int, $skip: Int, $sort: JSON) {
          clientActionSearchEntity(entityName: $entityName, filter: $filter, field: $field, limit: $limit, skip: $skip, sort: $sort)
        }`;

    const results: any = await this.apollo.query({
      query: query,
      variables: {
        entityName: entityName,
        filter: filter,
        field: field,
        limit: limit,
        skip: skip,
        sort: sort
      },
      fetchPolicy: 'network-only'
    }).toPromise();

    if (!!results && !!results.data['clientActionSearchEntity']) {
      return JSON.parse(JSON.stringify(results.data['clientActionSearchEntity']));
    } else {
      return JSON.parse(JSON.stringify([]));
    }
  }

  async countEntity(entityName, filter) {
    const query = gql`
        query clientActionCountEntity($entityName: String!, $filter: JSON!) {
          clientActionCountEntity(entityName: $entityName, filter: $filter)
        }`;

    const results: any = await this.apollo.query({
      query: query,
      variables: {
        entityName: entityName,
        filter: filter,
      },
      fetchPolicy: 'network-only'
    }).toPromise();

    if (!!results && !!results.data['clientActionCountEntity']) {
      return results.data['clientActionCountEntity'];
    } else {
      return 0;
    }
  }

  showToast(title, message, type: 'error' | 'warning' | 'success' | 'info' = "info") {
    let active: ActiveToast<any> = null;
    switch (type) {
      case 'error':
        active = this.toastService.error(message, title);
        break;
      case 'warning':
        active = this.toastService.warning(message, title);
        break;
      case 'success':
        active = this.toastService.success(message, title);
        break;
      case 'info':
        active = this.toastService.info(message, title);
        break;
    }
    setTimeout(() => {
      this.toastService.clear(active.toastId)
    }, 5000);
  }

  async getListSource(listID) {
    const query = gql`
        query clientActionGetListSource($listID: String!) {
          clientActionGetListSource(listID: $listID)
        }`;

    const results: any = await this.apollo.query({
      query: query,
      variables: {
        listID: listID
      },
      fetchPolicy: 'network-only'
    }).toPromise();

    if (!!results && !!results.data['clientActionGetListSource']) {
      return results.data['clientActionGetListSource'];
    } else {
      return [];
    }
  }
}
