import { Component, ViewEncapsulation, Input, OnInit, ViewChild, AfterViewInit, Renderer2, forwardRef, Output, EventEmitter } from '@angular/core';
import { AgmMap } from '@agm/core';
import { NgxModalOptions, ComponentModalRef, NgxModalStack } from 'ngx-multi-modal';
import { GoogleMapsSelectPointComponent } from '../google-maps-select-point/google-maps-select-point.component';
import { first } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-google-maps-view-point',
  templateUrl: './google-maps-view-point.component.html',
  styleUrls: ['./google-maps-view-point.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleMapsViewPointComponent),
      multi: true
    }
  ]
})
export class GoogleMapsViewPointComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild(AgmMap, { static: true }) private agmMap: any;
  @Input() controlName: string;
  @Input() viewData: any = null;
  @Output() updatedPosition = new EventEmitter<Object>();

  private onChange = (_: any) => { }
  private onTouch = () => { }
  public editMode: boolean = true;
  public coordinates = {
    lat: 18.644415,
    lng: -91.806706,
    zoom: 15,
  };

  public zoom: Number = 15;

  constructor(
    public modalService: NgxModalStack,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    if (!!this.viewData) {
      this.editMode = false;
      this.coordinates = this.viewData
    }
  }

  ngAfterViewInit() {
    this.agmMap.disableDefaultUI = true;
    this.agmMap.streetViewControl = false;
    this.agmMap.clickableIcons = false;
    this.triggerResize();
  }

  selectUbication() {
    const self = this;
    const options: NgxModalOptions = {};
    const selectPointMapModal: ComponentModalRef<GoogleMapsSelectPointComponent> = self.modalService.openFromComponent(
      GoogleMapsSelectPointComponent,
      options
    );
    selectPointMapModal.instance.position = this.coordinates;
    selectPointMapModal.instance.onFinish.pipe(first()).subscribe(update => {
      if (!!update) {
        this.onTouch();
        this.onChange(update);
        this.updatedPosition.emit(update);
      }
      selectPointMapModal.close();
      this.renderer.addClass(document.body, 'modal-open');
    });
  }

  triggerResize() {
    if (!!this.agmMap) {
      this.agmMap.triggerResize().then(() => {
        try {
          this.agmMap._mapsWrapper.setCenter({ lat: this.coordinates.lat, lng: this.coordinates.lng })
        } catch (ignored) {
          //-console.log(ignored);
        }
      });
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.coordinates = value || {
        lat: 18.644415,
        lng: -91.806706,
        zoom: 15,
      };
    } else {
      this.coordinates = {
        lat: 18.644415,
        lng: -91.806706,
        zoom: 15,
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.editMode = !isDisabled;
  }

}
