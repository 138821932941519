import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';


import { first, catchError, retry } from 'rxjs/operators';
import { NgxModalStack } from 'ngx-multi-modal';
import { ConnectionService } from './connection.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestsClientService {

  private connectionServices: ConnectionService = new ConnectionService();

  // INICIALIZACION
  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {

  }

  async requestFromInternalEndPoint(idEndPoint, params = {}): Promise<any> {
    return this.http.post(`${this.connectionServices.getUriDeployREST()}`, params, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token') || null
      },
      params: {
        id: idEndPoint
      }
    }).toPromise();
  }

  async requestFromInternatGetFile(scriptID, file): Promise<any> {
    return this.http.get(`${this.connectionServices.getUriFileREST()}?key=${scriptID}&file=${file}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token') || null
      }
    }).toPromise();
  }

  convertFileToString(fileObject): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  isValidURL(string) {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    var res = string.match(urlRegex);
    return (res !== null)
  };
}
