import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NavigationPaneService, ToolbarService, DetailsViewService, ContextMenuService, ToolbarClickEventArgs, ToolbarCreateEventArgs, FileManagerComponent, MenuOpenEventArgs, MenuClickEventArgs } from '@syncfusion/ej2-angular-filemanager';
import { L10n } from '@syncfusion/ej2-base';
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from 'app/services/connection.service';

@Component({
  selector: 'app-file-manager-braavo',
  templateUrl: './file-manager-braavo.component.html',
  styleUrls: ['./file-manager-braavo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NavigationPaneService, ToolbarService, DetailsViewService, ContextMenuService, ConnectionService]
})
export class FileManagerBraavoComponent implements OnInit, OnDestroy {
  @ViewChild(FileManagerComponent, { static: true })
  public fileManagerInstance: FileManagerComponent;
  @Input() fileAction: "View" | "Select" | "SelectMultiple" = "View";
  @Input() allowFiles: String[] | String = null;
  @Input() defaultPath: String = '/';
  @Output() onFinish = new EventEmitter<any>();

  public hostUrl: string;
  public ajaxSettings: any;
  // public toolbarSettings: ToolbarSettingsModel;
  public fileManagerSettings = {
    height: '800px',
    allowDragAndDrop: true,
    allowMultiSelection: true,
    contextMenuSettings: { file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Rename', '|', 'Details'], folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', '|', 'Details'], layout: ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', 'Upload', '|', 'Details', '|', 'SelectAll'], visible: true, },
    toolbarSettings: { items: ["NewFolder", "Upload", "Cut", "Copy", "Paste", "Delete", "Download", "Rename", "SortBy", "Refresh", "Selection", "View", "Details", "Close"], visible: true }
  };

  constructor(
    public toastrService: ToastrService,
    private connectionService: ConnectionService,
  ) {
    this.localeFileManager();
    this.initDataFileManager();
  }

  localeFileManager() {
    L10n.load({
      'es-MX': {
        'filemanager': {
          "Close": "Cerrar",
          "Tooltip-Close": "Cerrar ventana",
          "SelectFile": "Seleccionar",
          "Tooltip-SelectFile": "Elegir archivos",
          "NewFolder": "Nueva Carpeta",
          "Upload": "Subir",
          "Delete": "Eliminar",
          "Rename": "Renombrar",
          "Download": "Descargar",
          "Cut": "Cortar",
          "Copy": "Copiar",
          "Paste": "Pegar",
          "SortBy": "Ordenar",
          "Refresh": "Actualizar",
          "Item-Selection": "Seleccionado",
          "Items-Selection": "Seleccionados",
          "View": "Ver",
          "Details": "Detalles",
          "SelectAll": "Seleccionar todo",
          "Open": "Abrir",
          "Tooltip-NewFolder": "Nueva Carpeta",
          "Tooltip-Upload": "Subir",
          "Tooltip-Delete": "Eliminar",
          "Tooltip-Rename": "Renombrar",
          "Tooltip-Download": "Descargar",
          "Tooltip-Cut": "Cortar",
          "Tooltip-Copy": "Copiar",
          "Tooltip-Paste": "Pegar",
          "Tooltip-SortBy": "Ordenar",
          "Tooltip-Refresh": "Actualizar",
          "Tooltip-Selection": "Seleccion",
          "Tooltip-View": "Ver",
          "Tooltip-Details": "Detalles",
          "Tooltip-SelectAll": "Seleccionar todo",
          "Name": "Nombre",
          "Size": "Tamaño",
          "DateModified": "Fecha modificado",
          "DateCreated": "Fecha creado",
          "Path": "Ruta",
          "Modified": "Modificado",
          "Created": "Creado",
          "Location": "Ubicacion",
          "Type": "Tipo",
          "Permission": "Permiso",
          "Ascending": "Ascendente",
          "Descending": "Descendente",
          "View-LargeIcons": "Ver en iconos",
          "View-Details": "Ver en lista",
          "Search": "Buscar",
          "Button-Ok": "Aceptar",
          "Button-Cancel": "Cancelar",
          "Button-Yes": "Si",
          "Button-No": "No",
          "Button-Create": "Crear",
          "Button-Save": "Guardar",
          "Header-NewFolder": "Nueva carpeta",
          "Content-NewFolder": "Crear nueva carpeta",
          "Header-Rename": "Renombrar",
          "Content-Rename": "Renombrar",
          "Header-Rename-Confirmation": "Confirmar",
          "Content-Rename-Confirmation": "Confirmar acción",
          "Header-Delete": "Eliminar archivo",
          "Content-Delete": "Confirmar eliminación",
          "Header-Multiple-Delete": "Eliminar archivos",
          "Content-Multiple-Delete": "Confirmar eliminacion de {0} archivos",
          "Header-Duplicate": "Archivo ya existe",
          "Content-Duplicate": "{0} ya existe, ¿Desea remplazarlo?",
          "Header-Upload": "Subir archivos",
          "Error": "Error",
          "Validation-Empty": "El nombre del archivo o carpeta no puede estar vacio.",
          "Validation-Invalid": "Nombre {0} invalido, usar otro ",
          "Validation-NewFolder-Exists": "Archivo o carpeta con el nombre {0} ya existe.",
          "Validation-Rename-Exists": "No se puede cambiar el nombre de {0} a {1}",
          "Folder-Empty": "Carpeta vacia",
          "File-Upload": "Soltar archivos aqui para subir",
          "Search-Empty": "Sin resultados",
          "Search-Key": "Intente con otra palabra",
          "Sub-Folder-Error": "El destino de la carpeta es una subcarpeta de la carpeta actual.",
          "Access-Denied": "Denegado",
          "Access-Details": "Sin permisos para ver esta carpeta.",
          "Header-Retry": "Archivo ya existe",
          "Content-Retry": "Un archivo con este nombre ya existe, confirmar acción",
          "Button-Keep-Both": "Mantener ambos",
          "Button-Replace": "Remplazar",
          "Button-Skip": "Omitir",
          "ApplyAll-Label": "Aplicar a todos"
        }
      }
    });
  }

  initDataFileManager() {
    this.hostUrl = `${this.connectionService.getUri()}/`;
    this.ajaxSettings = {
      url: this.hostUrl + 'fileManager',
      getImageUrl: this.hostUrl + 'fileManager/GetImage',
      uploadUrl: this.hostUrl + 'fileManager/Upload',
      downloadUrl: this.hostUrl + 'fileManager/Download'
    };
    this.fileManagerSettings.toolbarSettings = { items: ['NewFolder', 'Upload', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection', 'View', 'Details', 'Close'], visible: true };
  }

  ngOnInit() {
    switch (this.fileAction) {
      case 'Select':
        this.fileManagerSettings['allowMultiSelection'] = false;
        this.fileManagerSettings['contextMenuSettings'] = { file: ['SelectFile', 'Rename', '|', 'Details'], folder: ['Open', '|', 'Rename', '|', 'Details'], layout: ['SortBy', 'View', 'Refresh', '|', 'Details'], visible: true, };
        this.fileManagerSettings['toolbarSettings'] = { items: ["SelectFile", 'NewFolder', "Upload", "Refresh", "Selection", "View", "Details", '|', "Close"], visible: true };
        break;
    }

    if (!!this.defaultPath) {
      const paths = this.defaultPath.split('/');
      let folder = '';
      let file = '';
      paths.forEach((path, index) => {
        if ((index + 1) < paths.length) {
          folder = folder.concat(path, '/')
        } else {
          file += path;
        }
      });
      this.fileManagerInstance.path = folder;
      this.fileManagerInstance.selectedItems = [file]
    }
  }

  toolbarClick(args: ToolbarClickEventArgs) {
    switch (args.item.id) {
      case this.fileManagerInstance.element.id + '_tb_close':
        this.closeModal();
        break;
      case this.fileManagerInstance.element.id + '_tb_selectfile':
        this.selectFile();
        break;
    }
  }

  toolbarCreate(args: ToolbarCreateEventArgs) {
    for (var i = 0; i < args.items.length; i++) {
      switch (args.items[i].id) {
        case this.fileManagerInstance.element.id + '_tb_close':
          args.items[i].prefixIcon = 'e-icons e-close';
          break;
        case this.fileManagerInstance.element.id + '_tb_selectfile':
          args.items[i].prefixIcon = 'e-icons e-check-mark';
          break;
      }
    }
  }

  menuOpen(args: MenuOpenEventArgs) {
    for (var i = 0; i < args.items.length; i++) {
      switch (args.items[i].id) {
        case this.fileManagerInstance.element.id + '_cm_selectfile':
          args.items[i].iconCss = 'e-icons e-check-mark';
          break;
      }
    }
  }

  // event for custom menu item
  menuClick(args: MenuClickEventArgs) {
    switch (args.item.id) {
      case this.fileManagerInstance.element.id + '_cm_selectfile':
        this.selectFile();
        break;
    }
  }

  selectFile() {
    const selectedFiles: any[] = this.fileManagerInstance.getSelectedFiles();
    //-console.log(selectedFiles);
    if (selectedFiles.length === 0) {
      this.toastrService.error("Seleccion no especificada", "Error");
      return;
    }
    if (selectedFiles.length > 1 && this.fileAction === 'Select') {
      this.toastrService.error("Seleccion multiple no permitida", "Error");
      return;
    }

    if (this.allowFiles instanceof Array) {
      if (!selectedFiles[0].isFile) {
        this.toastrService.error("Seleccion no valida", "Error");
        return;
      }
      if (this.allowFiles.length > 0 && this.allowFiles.findIndex(d => d.toLocaleLowerCase() === selectedFiles[0].type.replace('.', '').toLocaleLowerCase()) === -1) {
        const allowType = this.allowFiles.join(", ").toUpperCase();
        this.toastrService.error("Seleccion no valida, solo son validos las extensiones: " + allowType, "Error");
        return;
      }
    } else if (typeof this.allowFiles === 'string') {
      if (this.allowFiles !== 'Folder') {
        this.toastrService.error('Error de seleccion de carpeta, respuesta no permitida', "Error");
        return;
      }
    } else {
      this.toastrService.error('Error de seleccion, respuesta no permitida', "Error");
      return;
    }
    try {
      const path = selectedFiles[0].filterPath + selectedFiles[0].name;
      this.onFinish.emit({ path: path, filename: selectedFiles[0].name, folder: selectedFiles[0].filterPath });
    } catch (error) {
      this.toastrService.error(error, "Error");
    }
  }

  closeModal() {
    this.onFinish.emit(null);
  }

  ngOnDestroy(): void {

  }

}

